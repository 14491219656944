<template>
  <div
    class="px-md-12 px-3"
    >
    <v-row
      class="ma-0 fill-width align-center fill-height"
      >
      <v-col
        cols="12"
        class="bone fill-height rounded-xl"
        style="overflow: hidden; position: relative"
        >
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            left: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '10%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            right: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '40%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>
        <div
          style="max-width: 900px; position: relative"
          class="mx-auto py-16"
          >
          <div
            class="secondary--text text-h2 font-weight-bold mb-16"
            >
            <div
              class="mb-3"
              >
              ¿Qué es
            </div>
            <span
              class="secondary white--text rounded-lg px-3"
              >
              Partners
            </span>
            <br/>
            <span
              class="secondary white--text rounded-lg px-3"
              >
              Fans?
            </span>
          </div>

          <div
            class="text-h6 secondary--text"
            >
            <div>
              <span class="font-weight-bold">PartnersFans</span> nace con la idea de construir la primer red social real de compra / venta del mundo. En tiempos de crisis, queremos brindar un canal donde las tiendas puedan operar y promocionarse sin tener que exponerse a riesgos de ningún tipo, y donde los compradores puedan buscar recomendaciones, compartir sus compras y explorar nuevas experiencias desde la comodidad de sus hogares.
            </div>

            <div
              class="mt-8"
              >
              Buscamos construir comunidades sólidas para que las tiendas puedan llegar a lugares que les sería imposible por medios tradicionales y exponerlas al mundo de forma más democrática.
            </div>
          </div>

          <img
            :src="require('@/assets/img/like.png')"
            height="160px"
            style="position: absolute; top: 0; left: -180px"
            ></img>

          <img
            :src="require('@/assets/img/heart.png')"
            :style="{ 
              height: $vuetify.breakpoint.mdAndUp ? '200px' : '140px',
              width: $vuetify.breakpoint.mdAndUp ? '200px' : '140px',
              position: 'absolute',
              top: '9%',
              right: 0,
            }"
            ></img>

          <img
            :src="require('@/assets/img/like.png')"
            height="120px"
            style="position: absolute; top: 500px; right: -100px"
            ></img>

          <div
            class="d-flex flex-column flex-md-row justify-space-between"
            style="gap: 24px"
            :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: 200px' : 'margin-top: 100px'"
            >
            <div
              class="font-weight-bold text-h3 secondary--text"
              >
              Descarga <br />
              nuestros <br />
              manuales
            </div>

            <div
              class="text-h6 secondary--text"
              >
              Descarga nuestros manuales de uso para aprender <br />
              como navegar, editar, y todo lo necesario para que <br />
              tengas una óptima experiencia con nosotros.
            </div>
          </div>

          <div
            class="mt-12"
            >
            <downloads></downloads>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        class="rounded-xl white d-flex flex-column flex-md-row justify-space-between align-center py-16"
        style="gap: 48px; overflow: hidden; min-height: 100vh; position: relative"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 100px 8em;' : ''"
        >
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            left: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '40%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            right: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '10%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>

        <div
          :style="{ width: $vuetify.breakpoint.mdAndUp ? '390px' : '' }"
          style="position: relative; z-index: 2"
          class="fill-width"
          >
          <div
            class="text-md-h4 text-h5 accent--text font-weight-bold"
            >
            No hay <br />
            límites
          </div>

          <div
            class="font-weight-bold text-md-h2 text-h3 secondary--text mt-4"
            >
            Conoce <br />
            nuestras <br />
            tiendas.
          </div>

          <div
            class="font-weight-medium text-md-h5 text-h6 secondary--text mt-6"
            >
            Podrás visitar, seguir, apoyar y <br />
            recomendar distintas tiendas de <br />
            distintas categorías.
          </div>
        </div>

        <div
          style="position: relative; z-index: 2"
          >
          <img
            :src="require('@/assets/img/screen.png')"
            class="rounded-lg"
            style="width: 100%; height: auto; max-width: 900px"
            ></img>
        </div>
      </v-col>

      <v-col
        cols="12"
        class="rounded-xl bone d-flex flex-column flex-md-row justify-center align-center py-12"
        style="gap: 48px; min-height: 100vh; position: relative; overflow: hidden; position: relative"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 100px 8em;' : ''"
        >
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            right: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '20%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            left: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '30%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>

        <div
          style="position: relative; z-index: 2"
          :style="{ width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px', height: $vuetify.breakpoint.mdAndUp ? '' : '200px'}"
          class="d-flex align-center justify-center mt-12"
          >
          <img
            :src="require('@/assets/img/phone.png')"
            style="height: auto; z-index: 2; position: absolute"
            :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '550px' : '300px' }"
            ></img>

          <div
            style="position: absolute; z-index: 1"
            :style="{ 'width': $vuetify.breakpoint.mdAndUp ? '400px' : '200px', height: $vuetify.breakpoint.mdAndUp ? '400px' : '200px' }"
            class="rounded-circle background"
            ></div>
        </div>

        <div
          style="position: relative; z-index: 2"
          :style="{ width: $vuetify.breakpoint.mdAndUp ? '690px' : '' }"
          >
          <div
            class="text-md-h4 text-h5 accent--text font-weight-bold"
            >
            Comunidades <br/>
            desatadas
          </div>

          <div
            class="font-weight-bold text-md-h2 text-h3 secondary--text mt-4"
            >
            Conexión <br />
            sin límites
          </div>

          <div
            class="font-weight-medium text-md-h5 text-h6 secondary--text mt-6"
            >
            Unimos comercios y compradores de forma más humana, <br/>
            más cercana, más cálida, más moderna. <br/></br/>
            Brindamos un espacio único de compra donde
            encontrarás tus tiendas favoritas y podrás conectar con
            ellas y con otros compradores con tus mismos gustos.<br/><br/>
            Con <span class="font-weight-bold">PartnersFans</span> no te perderás de nada, nunca. <br/>
            Todas tus tiendas favoritas, al alcance de tu dedo.
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        class="rounded-xl white d-flex flex-column flex-md-row justify-center align-center"
        style="gap: 48px; min-height: 100vh; position: relative; overflow: hidden"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 100px 8em;' : ''"
        >
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            right: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '50%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            left: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '15%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>

        <div
          :style="{ width: $vuetify.breakpoint.mdAndUp ? '690px' : '' }"
          style="position: relative; z-index: 2"
          class="order-1 order-md-0 pb-12"
          >
          <div
            class="text-md-h4 text-h5 accent--text font-weight-bold"
            >
            Tu pyme en <br/>
            todos lados
          </div>

          <div
            class="font-weight-bold text-md-h2 text-h3 secondary--text mt-4"
            >
            Te damos <br/>
            ese empujón
          </div>

          <div
            class="font-weight-medium text-md-h5 text-h6 secondary--text mt-6"
            >
            Unimos comercios y compradores de forma más humana, <br/>
            más cercana, más cálida, más moderna <br/></br/>
            Brindamos un espacio único de compra donde
            encontrarás tus tiendas favoritas y podrás conectar con
            ellas y con otros compradores con tus mismos gustos.<br/><br/>
            Con <span class="font-weight-bold">PartnersFans</span> no te perderás de nada, nunca. <br/>
            Todas tus tiendas favoritas, al alcance de tu dedo.
          </div>
        </div>

        <div
          style="position: relative; z-index: 2"
          :style="{ width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px', height: $vuetify.breakpoint.mdAndUp ? '' : '200px'}"
          class="d-flex align-center justify-center mt-12"
          >
          <img
            :src="require('@/assets/img/rocket.png')"
            style="height: auto; z-index: 2; position: absolute"
            :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '550px' : '300px' }"
            ></img>

          <div
            style="position: absolute; z-index: 1"
            :style="{ 'width': $vuetify.breakpoint.mdAndUp ? '400px' : '200px', height: $vuetify.breakpoint.mdAndUp ? '400px' : '200px' }"
            class="rounded-circle background"
            ></div>
        </div>
      </v-col>

      <v-col
        cols="12"
        class="rounded-xl bone d-flex flex-column flex-md-row justify-center align-center"
        style="position: relative; overflow: hidden"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 100px 8em;' : ''"
        >
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            right: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '70%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>
        <div
          :style="{ 
            height: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px',
            position: 'absolute',
            left: $vuetify.breakpoint.mdAndUp ? '-400px' : '-200px',
            top: '-40%',
            opacity: '0.5',
          }"
          class="rounded-circle background"
          ></div>

        <div
          style="position: relative; z-index: 2"
          :style="{ width: $vuetify.breakpoint.mdAndUp ? '600px' : '300px', height: $vuetify.breakpoint.mdAndUp ? '' : '200px'}"
          class="d-flex align-center justify-center mt-12"
          >
          <img
            :src="require('@/assets/img/bags.png')"
            style="height: auto; z-index: 2; position: absolute"
            :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '550px' : '300px' }"
            ></img>

          <div
            style="position: absolute; z-index: 1"
            :style="{ 'width': $vuetify.breakpoint.mdAndUp ? '400px' : '200px', height: $vuetify.breakpoint.mdAndUp ? '400px' : '200px' }"
            class="rounded-circle background"
            ></div>
        </div>

        <div
          :style="{ width: $vuetify.breakpoint.mdAndUp ? '600px' : '' }"
          style="position: relative; z-index: 2"
          class="py-12"
          >
          <div
            class="text-md-h4 text-h5 accent--text font-weight-bold"
            >
            El cielo es <br/>
            el límite
          </div>

          <div
            class="font-weight-bold text-md-h2 text-h3 secondary--text mt-4"
            >
            Más que un simple <br/>
            marketplace
          </div>

          <div
            class="font-weight-medium text-md-h5 text-h6 secondary--text mt-6"
            >
            Construimos una comunidad de compra / venta más
            directa, más confiable.<br/><br/>
            Nuestro objetivo es simple:<br/>
            <span class="font-weight-bold">Hacer tu vida más fácil.</span><br/><br/>
            Todos nuestros Partners están cuidadosamente<br/>
            seleccionados para brindar la mejor experiencia posible.
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Downloads from './Downloads'

export default {
  components: {
    Downloads
  }
}
</script>
