<template>
  <v-row>
    <v-col
      cols="6"
      md="3"
      >
      <v-card
        flat
        color="background"
        height="150"
        class="rounded-xl"
        @click="open('/PF_MANUAL_CONFIGURACIONES.pdf')"
        >
        <v-card-text
          class="secondary--text text-h5 font-weight-medium"
          >
          <div>
            Cómo <br />
            Configurar <br />
            Tienda
          </div>

          <v-icon
            style="position: absolute; bottom: 16px; right: 16px"
            large
            color="secondary"
            >
            mdi-download
          </v-icon>
        </v-card-text>
      </v-card>
    </v-col>

    <template
      v-if="false"
      >
      <v-col
        cols="6"
        md="3"
        >
        <v-card
          flat
          color="accent"
          height="150"
          class="rounded-xl"
          >
          <v-card-text
            class="primary--text text-h5 font-weight-medium"
            >
            <div>
              Cómo <br />
              Comprar
            </div>

            <v-icon
              style="position: absolute; bottom: 16px; right: 16px"
              large
              color="primary"
              >
              mdi-download
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="6"
        md="3"
        >
        <v-card
          flat
          color="primary"
          height="150"
          class="rounded-xl"
          >
          <v-card-text
            class="accent--text text-h5 font-weight-medium"
            >
            <div>
              Cómo <br />
              Vender
            </div>

            <v-icon
              style="position: absolute; bottom: 16px; right: 16px"
              large
              color="accent"
              >
              mdi-download
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="6"
        md="3"
        >
        <v-card
          flat
          color="secondary"
          height="150"
          class="rounded-xl"
          >
          <v-card-text
            class="background--text text-h5 font-weight-medium"
            >
            <div>
              Cómo ser<br />
              Miembro
            </div>

            <v-icon
              style="position: absolute; bottom: 16px; right: 16px"
              large
              color="background"
              >
              mdi-download
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  methods: {
    open (route) {
      let routeData = this.$router.resolve(route)
      window.open(routeData.href, '_blank');
    }
  },
}
</script>
